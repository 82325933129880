import NavbarTop from "./NavbarTop";
import { BrowserRouter as Router, Route, Switch, Link } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { MdClose, MdImportExport } from "react-icons/md";
import styled from "styled-components/macro";
import Home from "./Home";
import NavbarBottom from "./NavbarBottom";
import Login from "./Login";
import "./App.css";
import { Redirect } from "react-router";
import Posts from "./dashboard/Posts";
import Post from "./Post";
import CreatePost from "./dashboard/CreatePost";
import CreateCollection from "./dashboard/CreateCollection";
import Collections from "./dashboard/Collections";
import EditHome from "./dashboard/EditHome";
import EditCollection from "./dashboard/EditCollection";
import EditPost from "./dashboard/EditPost";
import { isEmpty } from "./globalFunctions/isEmpty";

export default function App() {
  const [store, setStore] = useState();
  const [umfrage, setUmfrage] = useState("");
  const [ergebnisJa, setErgebnisJa] = useState("");
  const [ergebnisNein, setErgebnisNein] = useState("");
  const [response, setResponse] = useState("");
  const [responseUser, setResponseUser] = useState("");
  const [userData, setUserData] = useState([]);

  // get umfragewerte
  useEffect(async () => {
    const res = await fetch(
      "https://www.menschmeierapi.exconsilio.com/publicPages/pollCount.php?umfrage1",
      {
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "GET",
        // body: JSON.stringify({
        //  companyname: "dancemonkey"
        // })
      }
    );
    const resp = await res.text().then((value) => {
      setResponse(JSON.parse(value));
    });
  }, []);

  // collection in state packen
  useEffect(() => {
    if (ergebnisJa === "" && ergebnisNein === "" && response !== "") {
      setErgebnisJa(response[0].yes);
      setErgebnisNein(response[0].no);
    }
  }, [response]);

  const getData = () => {
    try {
      const jsonValue = localStorage.getItem("@jwtmenschmeier");
      var resJSON = jsonValue.substring(1, jsonValue.length - 1);
      setStore({ JWT: resJSON });
    } catch (e) {
      // error reading value
    }
  };

  const destroySession = () => {
    try {
      localStorage.removeItem("@jwtmenschmeier");
      setStore();
    } catch (e) {
      // saving error
    }
  };

  if (isEmpty(store) && localStorage.getItem("@jwtmenschmeier")) {
    getData();
  }

  // umfrageState. zu beginn empty
  // bei einem klick auf den button ja oder nein soll der umfragestate geändert werden
  const handleClickUmfrageButton = (e) => {
    if (e.target.id === "yes") {
      const res = fetch(
        "https://www.menschmeierapi.exconsilio.com/publicPages/pollCount.php?umfrage1=yes",
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "POST",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      let neuesErgebnis = parseInt(ergebnisJa) + 1;
      setErgebnisJa(neuesErgebnis);
    }
    if (e.target.id === "no") {
      const res = fetch(
        "https://www.menschmeierapi.exconsilio.com/publicPages/pollCount.php?umfrage1=no",
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "POST",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      let neuesErgebnis = parseInt(ergebnisNein) + 1;
      setErgebnisNein(neuesErgebnis);
    }
    setUmfrage("beendet");
    //beendet status soll in localstorage gespeichert werden
    localStorage.setItem("@umfragemenschmeier", "beendet");
  };

  //wenn umfrage beendet, soll das ergebnis aus der datenbank gefetch werden per useEffect

  //aus dem localstoarge soll per useEffect geschaut werden, ob man schonmal an der umfrage teilgenommen hat
  if (umfrage === "" && localStorage.getItem("@umfragemenschmeier")) {
    if (localStorage.getItem("@umfragemenschmeier")) {
      setUmfrage("beendet");
    } else {
      setUmfrage("");
    }
  }

  const fetchUmfrageErgebnis = () => {
    // fetch
    // .then setErgebnisJa(value)
    //.then setErgebnisNein(value)
  };

  //get userData
  useEffect(async () => {
    if (!isEmpty(store)) {
      const res = await fetch(
        "https://www.menschmeierapi.exconsilio.com/users/",
        {
          headers: {
            Authorization: `Bearer ${store.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res
        .text()
        .then((value) => setResponseUser(JSON.parse(value)));
    }
  }, [store]);

  // collection in state packen
  useEffect(() => {
    if (responseUser !== "") {
      setUserData(responseUser);
    }
  }, [responseUser, store]);

  return (
    <div className="app">
      {/* Routing */}
      <Router>
        <div>
          <Switch>
            {/* Startseite: Home */}
            <Route exact path="/">
              <Home
                store={store}
                handleClickUmfrageButton={handleClickUmfrageButton}
                umfrage={umfrage}
                ergebnisJa={ergebnisJa}
                ergebnisNein={ergebnisNein}
              />
            </Route>
            {/* Unterseite: Über Uns */}
            <Route exact path="/login">
              {!isEmpty(store) ? (
                <Redirect to="/" />
              ) : (
                <Login store={store} setStore={setStore} />
              )}
            </Route>
            <Route exact path="/dashboard/posts">
              {isEmpty(store) ? (
                <Redirect to="/" />
              ) : (
                <Posts store={store} setStore={setStore} />
              )}
            </Route>
            <Route exact path="/dashboard/collections">
              {isEmpty(store) ? (
                <Redirect to="/" />
              ) : (
                <Collections store={store} setStore={setStore} />
              )}
            </Route>
            <Route exact path="/dashboard/edit/homepage">
              {isEmpty(store) ? (
                <Redirect to="/" />
              ) : (
                <EditHome store={store} setStore={setStore} />
              )}
            </Route>
            <Route exact path="/dashboard/edit/collection/:id">
              {isEmpty(store) ? (
                <Redirect to="/" />
              ) : (
                <EditCollection store={store} setStore={setStore} />
              )}
            </Route>
            <Route exact path="/dashboard/edit/post/:id">
              {isEmpty(store) ? (
                <Redirect to="/" />
              ) : (
                <EditPost store={store} setStore={setStore} />
              )}
            </Route>
            {/* Unterseite: Aktuelle Themen */}
            {/* Unterseite: Kultur */}
            {/* Unterseite: Meinungen */}
            {/* Unterseite: Mitglieder
             */}

            <Route exact path="/post/create">
              {isEmpty(store) ? (
                <Redirect to="/" />
              ) : (
                <CreatePost
                  store={store}
                  setStore={setStore}
                  userData={userData}
                />
              )}
            </Route>

            <Route exact path="/collection/create">
              {isEmpty(store) ? (
                <Redirect to="/" />
              ) : (
                <CreateCollection
                  store={store}
                  setStore={setStore}
                  userData={userData}
                />
              )}
            </Route>

            <Route path="/post/:id">
              {isEmpty(store) ? (
                <Redirect to="/" />
              ) : (
                <Post store={store} setStore={setStore} />
              )}
            </Route>
            {/*  Neuen Beitrag erstellen */}
            {/*  Neue Kollektion erstellen */}
            {/*  Neuen Beitrag erstellen */}
          </Switch>
          {/* Navigationsleiste TOP */}
          {/* Navigationsleiste: Bottom */}
          <NavbarTop
            store={store}
            setStore={setStore}
            destroySession={destroySession}
          />
          <NavbarBottom store={store} setStore={setStore} userData={userData} />
        </div>
      </Router>
    </div>
  );
}

const CloseIcon = styled(MdClose)`
  font-size: 33px;
  color: #000;
  width: auto;
  justify-content: center;
  padding-right: 18px;
  padding-top: 3px;
`;
