import React from "react";

function HomePost({
  img,
  direction,
  title,
  subtitle,
  text,
  sourceText,
  sourceLink,
  sourceDescription,
  bubbleSlogan,
  status,
}) {
  const handleClick = () => {
    window.location.href = sourceLink;
  };

  if (status === "inaktiv") {
    return <div></div>;
  }
  if (direction === "left") {
    return (
      <div className="homepost">
        <div className="home__dynamicContentFirst">
          <div className="home__dynamicContentFirstContent">
            <div className="home__dynamicContentFirstContentLeft">
              <img
                className="home__dynamicContentFirstContentLeftImg"
                src={img}
              />
            </div>
            <div className="home__dynamicContentFirstContentMiddle">
              <div className="home__dynamicContentFirstContentMiddleHeadline">
                <div>{title}</div>
              </div>
              <div className="home__dynamicContentFirstContentMiddleHeadlineSubWrapper">
                <div className="home__dynamicContentFirstContentMiddleHeadlineSubtext">
                  <div>{subtitle}</div>
                </div>
                <div className="home__dynamicContentFirstContentMiddleBody">
                  <div>{text}</div>
                </div>
                <div className="home__dynamicContentFirstContentMiddleLink">
                  {sourceText}
                  <div
                    className="btn-small marginLeft20"
                    title="Link zur Quelle"
                    onClick={() => handleClick()}
                  >
                    HIER
                  </div>
                </div>
                <div className="home__dynamicContentFirstContentMiddleSource">
                  {sourceDescription}
                </div>
              </div>
            </div>
            <div className="home__dynamicContentFirstContentRight">
              <div className="home__dynamicContentFirstContentRightIcon">
                <div className="home__dynamicContentFirstContentRightIconText">
                  <div className="home__dynamicContentFirstContentRightIconTextBox">
                    MENSCH MEIER,
                    <br />
                    <span className="home__dynamicContentFirstContentRightIconTextHighlight">
                      {bubbleSlogan}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  /* img right, bubble left */
  return (
    <div className="homepost">
      <div className="home__dynamicContentFirst">
        <div className="home__dynamicContentFirstContent">
          <div className="home__dynamicContentFirstContentRight">
            <div className="home__dynamicContentFirstContentRightIconV2">
              <div className="home__dynamicContentFirstContentRightIconTextV2">
                <div className="home__dynamicContentFirstContentRightIconTextBox">
                  MENSCH MEIER,
                  <br />
                  <span className="home__dynamicContentFirstContentRightIconTextHighlight">
                    {bubbleSlogan}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className="home__dynamicContentFirstContentMiddleV2">
            <div className="home__dynamicContentFirstContentMiddleHeadlineV2">
              <div>{title}</div>
            </div>
            <div className="home__dynamicContentFirstContentMiddleHeadlineSubWrapper">
              <div className="home__dynamicContentFirstContentMiddleHeadlineSubtext">
                <div>{subtitle}</div>
              </div>
              <div className="home__dynamicContentFirstContentMiddleBody">
                <div>{text}</div>
              </div>
              <div className="home__dynamicContentFirstContentMiddleLink">
                {sourceText}
                <div
                  className="btn-small marginLeft20"
                  title="Link zur Quelle"
                  onClick={() => handleClick()}
                >
                  HIER
                </div>
              </div>
              <div className="home__dynamicContentFirstContentMiddleSource">
                {sourceDescription}
              </div>
            </div>
          </div>
          <div className="home__dynamicContentFirstContentLeftV2">
            <img
              className="home__dynamicContentFirstContentLeftImgV2"
              src={img}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HomePost;
