import React from "react";
import "./styles/NavbarTop.css";
import { mdiAccountCircle } from "@mdi/js";
import Icon from "@mdi/react";
import { Link } from "react-router-dom";
import { Redirect } from "react-router";

function NavbarTop({ store, setStore, destroySession }) {
  const isEmpty = (obj) => {
    if (obj === undefined || obj === null || Object.keys(obj).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  const handleLogout = () => {
    destroySession();
  };

  return (
    <div className="navbartop">
      <div className="navbartop__wrapper">
        <Link to="/">
          <div className="navbartop__wrapperLeft">
            <div className="navbartop__wrapperLeftLogo shadow"></div>
          </div>
        </Link>
        <div className="navbartop__wrapperCenter">
          <div className="navbartop__wrapperCenterItem">ÜBER UNS</div>
          <div className="navbartop__wrapperCenterItem">LASS UNS REDEN</div>
          <div className="navbartop__wrapperCenterItem">GOOD 2 KNOW</div>
          <div className="navbartop__wrapperCenterItem">GESEHEN & GEHÖRT</div>
          <div className="navbartop__wrapperCenterItem">CLUB FORUM</div>
          <div className="navbartop__wrapperCenterItem">MITGLIED WERDEN</div>
        </div>
        {isEmpty(store) ? (
          <Link to="/login">
            <div className="navbartop__wrapperRight">
              Login
              <div className="navbartop__wrapperRightIcon">
                <Icon
                  path={mdiAccountCircle}
                  title="User Profile"
                  size={1}
                  color="black"
                />
              </div>
            </div>
          </Link>
        ) : (
          <div className="navbartop__wrapperRight" onClick={handleLogout}>
            Logout
            <div className="navbartop__wrapperRightIcon">
              <Icon
                path={mdiAccountCircle}
                title="User Profile"
                size={1}
                color="black"
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default NavbarTop;
