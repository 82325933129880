import React from "react";
import { Link } from "react-router-dom";
import cardImgExample from "../images/cardImgExample.jpg";

function HomeThemeCard({ prop }) {
  let routeToPost = "/post/" + prop.id;

  if (prop.status === "inaktiv") {
    return <div></div>;
  }
  return (
    <div>
      <div className="home__themeWrapperCardsCard">
        <div className="home__themeWrapperCardsCardImgDiv">
          <img
            className="home__themeWrapperCardsCardImgDivImg"
            src={prop.imgURL}
          />
        </div>
        <div className="home__themeWrapperCardsCardTitle">{prop.category}</div>
        <div className="home__themeWrapperCardsCardText">{prop.title}</div>
        <Link to={routeToPost}>
          <div className="home__themeWrapperCardsCardCTA btn-small">LESEN</div>
        </Link>
      </div>
    </div>
  );
}

export default HomeThemeCard;
