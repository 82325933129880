import React, { useEffect, useState } from "react";
import "../styles/Dashboard.css";
import { FiEdit } from "react-icons/fi";
import styled from "styled-components/macro";
import { Link, useHistory } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import { transformUnixDateToNormalDate } from "../globalFunctions/transformUnixDateToNormalDate";
import { getTodayAsUnixDate } from "../globalFunctions/getTodayAsUnixDate";
import { Redirect } from "react-router";
import { isEmpty } from "../globalFunctions/isEmpty";

function CreateCollection({ store, setStore, userData }) {
  const [title, setTitle] = useState("");
  const [beitrag1, setBeitrag1] = useState([]);
  const [beitrag2, setBeitrag2] = useState([]);
  const [beitrag3, setBeitrag3] = useState([]);

  const [posts, setPosts] = useState([]);
  const [response, setResponse] = useState("");

  const isEmpty = (obj) => {
    if (obj === undefined || obj === null || Object.keys(obj).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  // get all posts
  useEffect(async () => {
    // fetch posts
    const res = await fetch(
      "https://www.menschmeierapi.exconsilio.com/posts/",
      {
        headers: {
          Authorization: `Bearer ${store?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "GET",
        // body: JSON.stringify({
        //  companyname: "dancemonkey"
        // })
      }
    );
    const resp = await res.text().then((value) => {
      setResponse(value);
    });
  }, [store]);

  useEffect(() => {
    if (response !== "") {
      setPosts(JSON.parse(response));
    }
  }, [response]);

  const url = "https://www.menschmeierapi.exconsilio.com/images/process.php";
  const form = document.querySelector("form");
  let submitButton = document.getElementById("submitButton123");

  useEffect(() => {
    submitButton = document.getElementById("submitButton123");
    if (
      title === "" ||
      isEmpty(beitrag1) ||
      isEmpty(beitrag2) ||
      isEmpty(beitrag3)
    ) {
      if (submitButton !== null) {
        submitButton.disabled = true;
      }
    } else {
      if (submitButton !== null) {
        submitButton.disabled = false;
      }
    }
  }, [title, beitrag1, beitrag2, beitrag3]);

  let history = useHistory();

  const handleUpload = async (imgurl) => {
    let postString =
      '[{"id":"' +
      beitrag1 +
      '"},{"id":"' +
      beitrag2 +
      '"},{"id":"' +
      beitrag3 +
      '"}]';

    await fetch("https://www.menschmeierapi.exconsilio.com/collections/", {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${store?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        title: title,
        createdAt: getTodayAsUnixDate(),
        creatorID: "1",
        status: "aktiv",
        posts: postString,
        creatorID: userData.id ? userData.id : "0",
      }),
    }).then(() => history.push("/dashboard/collections"));
  };

  return (
    <div className="dashboard">
      <form action="javascript:void(0);">
        <div className="dashboard__wrapper">
          <div className="dashboard__topline">
            <div className="dashboard__toplineHeadline">
              Neue Kollektion erstellen
            </div>
          </div>
          <div className="dashboard__collectionInputs">
            <div className="dashboard__collectionInputsTop">
              <input
                className="dashboard__inputsInputOneLiner"
                placeholder="Titel"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="dashboard__collectionInputsBottom">
              <select
                value={beitrag1}
                className="dashboard__inputsInputOneLiner"
                placeholder="Titel"
                onChange={(e) => setBeitrag1(e.target.value)}
              >
                <option>Beitrag 1</option>
                {posts.map((post) => {
                  return (
                    <option key={post.id} value={post.id}>
                      {post.title}
                    </option>
                  );
                })}
              </select>
              <select
                value={beitrag2}
                className="dashboard__inputsInputOneLiner"
                placeholder="Titel"
                onChange={(e) => setBeitrag2(e.target.value)}
              >
                <option>Beitrag 2</option>
                {posts.map((post) => {
                  return (
                    <option key={post.id} value={post.id}>
                      {post.title}
                    </option>
                  );
                })}
              </select>
              <select
                value={beitrag3}
                className="dashboard__inputsInputOneLiner"
                placeholder="Titel"
                onChange={(e) => setBeitrag3(e.target.value)}
              >
                <option>Beitrag 3</option>
                {posts.map((post) => {
                  return (
                    <option key={post.id} value={post.id}>
                      {post.title}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="dashboard__inputsSubmit">
            <input
              type="submit"
              name="submit"
              id="submitButton123"
              onClick={(e) => handleUpload(e)}
              title="Einloggen"
              className="dashboard__inputsSubmitButton"
              value="Beitrag erstellen"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreateCollection;

const EditIcon = styled(FiEdit)`
  font-size: 30px;
  color: #000;
  width: auto;
  justify-content: center;
  padding-right: 18px;
  padding-top: 3px;
`;
