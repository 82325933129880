import React, { useEffect, useState } from "react";
import "../styles/Dashboard.css";
import { FiEdit } from "react-icons/fi";
import styled from "styled-components/macro";
import { Link, useHistory } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import { transformUnixDateToNormalDate } from "../globalFunctions/transformUnixDateToNormalDate";
import { getTodayAsUnixDate } from "../globalFunctions/getTodayAsUnixDate";
import { Redirect, useParams } from "react-router";
import { isEmpty } from "../globalFunctions/isEmpty";
import { RiCheckboxCircleLine, RiCheckboxCircleFill } from "react-icons/ri";

function EditCollection({ store, setStore }) {
  const { id } = useParams();
  const [title, setTitle] = useState("");
  const [beitrag1, setBeitrag1] = useState([]);
  const [beitrag2, setBeitrag2] = useState([]);
  const [beitrag3, setBeitrag3] = useState([]);
  const [active, setActive] = useState("");

  const [collection, setCollection] = useState([]);
  const [posts, setPosts] = useState([]);
  const [response, setResponse] = useState("");

  // get collection
  useEffect(async () => {
    // fetch posts
    const res = await fetch(
      "https://www.menschmeierapi.exconsilio.com/collections/?id=" + id,
      {
        headers: {
          Authorization: `Bearer ${store?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "GET",
        // body: JSON.stringify({
        //  companyname: "dancemonkey"
        // })
      }
    );
    const resp = await res.text().then((value) => {
      setResponse(value);
    });
  }, [store, id]);

  // collection in state packen
  useEffect(() => {
    if (response !== "") {
      setCollection(JSON.parse(response));
    }
  }, [response]);

  // posts holen aus der collection
  useEffect(async () => {
    if (!isEmpty(collection)) {
      setPosts([]);
      // fetch posts
      let res2 = await fetch(
        "https://www.menschmeierapi.exconsilio.com/posts/",
        {
          headers: {
            Authorization: `Bearer ${store?.JWT}`,
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      let resp = await res2.text().then((value) => {
        setPosts(JSON.parse(value));
      });
    }
  }, [collection]);

  useEffect(() => {
    if (!isEmpty(collection)) {
      setTitle(collection[0].title);
      setBeitrag1(JSON.parse(collection[0].posts)[0].id);
      setBeitrag2(JSON.parse(collection[0].posts)[1].id);
      setBeitrag3(JSON.parse(collection[0].posts)[2].id);
      setActive(collection[0].status);
    }
  }, [collection]);

  const url = "https://www.menschmeierapi.exconsilio.com/images/process.php";
  const form = document.querySelector("form");
  let submitButton = document.getElementById("submitButton123");

  useEffect(() => {
    submitButton = document.getElementById("submitButton123");
    if (
      title === "" ||
      isEmpty(beitrag1) ||
      isEmpty(beitrag2) ||
      isEmpty(beitrag3)
    ) {
      if (submitButton !== null) {
        submitButton.disabled = true;
      }
    } else {
      if (submitButton !== null) {
        submitButton.disabled = false;
      }
    }
  }, [title, beitrag1, beitrag2, beitrag3]);

  let history = useHistory();

  const handleUpload = async (imgurl) => {
    let postString =
      '[{"id":"' +
      beitrag1 +
      '"},{"id":"' +
      beitrag2 +
      '"},{"id":"' +
      beitrag3 +
      '"}]';

    await fetch(
      "https://www.menschmeierapi.exconsilio.com/collections/?id=" + id,
      {
        method: "POST",
        headers: {
          Authorization: `Bearer ${store?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          title: title,
          status: active,
          posts: postString,
        }),
      }
    ).then(() => history.push("/dashboard/collections"));
  };

  const changeActive = () => {
    if (active === "aktiv") {
      setActive("inaktiv");
    } else {
      setActive("aktiv");
    }
  };

  return (
    <div className="dashboard">
      <form action="javascript:void(0);">
        <div className="dashboard__wrapper">
          <div className="dashboard__topline">
            <div className="dashboard__toplineHeadline">
              Kollektion bearbeiten
            </div>
          </div>
          <div className="dashboard__collectionInputs">
            <div className="dashboard__collectionInputsTop">
              <input
                className="dashboard__inputsInputOneLiner"
                placeholder="Titel"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>
            <div className="dashboard__collectionInputsBottom">
              <select
                value={beitrag1}
                className="dashboard__inputsInputOneLiner"
                placeholder="Titel"
                onChange={(e) => setBeitrag1(e.target.value)}
              >
                {posts.map((post) => {
                  return (
                    <option key={post.id} value={post.id}>
                      {post.title}
                    </option>
                  );
                })}
              </select>
              <select
                value={beitrag2}
                className="dashboard__inputsInputOneLiner"
                placeholder="Titel"
                onChange={(e) => setBeitrag2(e.target.value)}
              >
                {posts.map((post) => {
                  return (
                    <option key={post.id} value={post.id}>
                      {post.title}
                    </option>
                  );
                })}
              </select>
              <select
                value={beitrag3}
                className="dashboard__inputsInputOneLiner"
                placeholder="Titel"
                onChange={(e) => setBeitrag3(e.target.value)}
              >
                {posts.map((post) => {
                  return (
                    <option key={post.id} value={post.id}>
                      {post.title}
                    </option>
                  );
                })}
              </select>
              <div className="dashboard__inputsInputImg">
                {active === "aktiv" ? (
                  <div
                    className="dashboard__inputsInputCheckbox hoverable"
                    onClick={() => changeActive()}
                  >
                    <CheckIconFilled /> Beitrag aktiv
                  </div>
                ) : (
                  <div
                    className="dashboard__inputsInputCheckbox hoverable"
                    onClick={() => changeActive()}
                  >
                    <CheckIconNotFilled /> Beitrag inaktiv
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="dashboard__inputsSubmit">
            <input
              type="submit"
              name="submit"
              id="submitButton123"
              onClick={(e) => handleUpload(e)}
              title="Einloggen"
              className="dashboard__inputsSubmitButton"
              value="Änderung speichern"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditCollection;

const CheckIconNotFilled = styled(RiCheckboxCircleLine)`
  font-size: 35px;
  width: auto;
  justify-content: center;
  padding-right: 18px;
  padding-top: 3px;
`;

const CheckIconFilled = styled(RiCheckboxCircleFill)`
  font-size: 35px;
  width: auto;
  justify-content: center;
  padding-right: 18px;
  padding-top: 3px;
`;
