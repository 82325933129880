import React, { useEffect, useState } from "react";
import "../styles/Dashboard.css";
import { RiCheckboxCircleLine, RiCheckboxCircleFill } from "react-icons/ri";
import styled from "styled-components/macro";
import { Link, useHistory } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import { transformUnixDateToNormalDate } from "../globalFunctions/transformUnixDateToNormalDate";
import { getTodayAsUnixDate } from "../globalFunctions/getTodayAsUnixDate";
import { Redirect, useParams } from "react-router";
import { isEmpty } from "../globalFunctions/isEmpty";

function EditPost({ store, setStore }) {
  const { id } = useParams();

  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [text, setText] = useState("");
  const [sourceURL, setSourceURL] = useState("");
  const [sourceText, setSourceText] = useState("");
  const [sourceDescription, setSourceDesription] = useState("");
  const [bubbleSlogan, setBubbleSlogan] = useState("");
  const [category, setCategory] = useState("");
  const [active, setActive] = useState("aktiv");

  const [imageUploads, setImageUploads] = useState("");
  const [imageURL, setImageURL] = useState("");

  const [beitrag1, setBeitrag1] = useState([]);
  const [beitrag2, setBeitrag2] = useState([]);
  const [beitrag3, setBeitrag3] = useState([]);

  const [collection, setCollection] = useState([]);
  const [post, setPost] = useState([]);
  const [response, setResponse] = useState("");

  // get collection
  useEffect(async () => {
    // fetch posts
    const res = await fetch(
      "https://www.menschmeierapi.exconsilio.com/posts/?id=" + String(id),
      {
        headers: {
          Authorization: `Bearer ${store?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "GET",
        // body: JSON.stringify({
        //  companyname: "dancemonkey"
        // })
      }
    );
    const resp = await res.text().then((value) => {
      setResponse(value);
    });
  }, [store, id]);

  // collection in state packen
  useEffect(() => {
    if (response !== "") {
      setPost(JSON.parse(response));
    }
  }, [response]);

  // wenn ein neues bild ausgewählt wurde, soll das alte verschwinden
  useEffect(() => {
    if (imageUploads !== "") {
      setImageURL("");
    }
  }, [imageUploads]);

  useEffect(() => {
    if (!isEmpty(post)) {
      setTitle(post[0].title);
      setSubtitle(post[0].subtitle);
      setText(post[0].text);
      setSourceURL(post[0].sourceURL);
      setSourceDesription(post[0].sourceDescription);
      setSourceText(post[0].sourceText);
      setBubbleSlogan(post[0].bubbleSlogan);
      setCategory(post[0].category);
      setImageURL(post[0].imgURL);
      setActive(post[0].status);
    }
  }, [post]);

  const url = "https://www.menschmeierapi.exconsilio.com/images/process.php";
  const form = document.querySelector("form");
  let submitButton = document.getElementById("submitButton123");

  // submitbutton disablen wenn einträge fehlen
  useEffect(() => {
    submitButton = document.getElementById("submitButton123");
    if (title === "") {
      if (submitButton !== null) {
        submitButton.disabled = true;
      }
    } else {
      if (submitButton !== null) {
        submitButton.disabled = false;
      }
    }
  }, [title, beitrag1, beitrag2, beitrag3]);

  //upload aller daten
  const handleUpload = async (e) => {
    e.preventDefault();

    if (submitButton !== null) {
      submitButton.disabled = true;
    }

    //wenn ein neues bild hochgeladen werden soll, ist imageURL leer,
    if (imageURL === "") {
      const files = document.querySelector("[type=file]").files;
      const formData = new FormData();

      for (let i = 0; i < files.length; i++) {
        let file = files[i];

        formData.append("files[]", file);
      }

      const res = await fetch(url, {
        headers: {
          Authorization: `Bearer ${store?.JWT}`,
        },
        method: "POST",
        body: formData,
      });

      await res.text().then((value) => uploadToDatabase(value));
    } else {
      uploadToDatabase(imageURL);
    }
  };

  let history = useHistory();

  const uploadToDatabase = async (imgurl) => {
    await fetch("https://www.menschmeierapi.exconsilio.com/posts/?id=" + id, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${store?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        title: title,
        subtitle: subtitle,
        text: text,
        sourceURL: sourceURL,
        sourceText: sourceText,
        sourceDescription: sourceDescription,
        imgURL: imgurl,
        creatorID: "1",
        bubbleSlogan: bubbleSlogan,
        category: category,
        status: active,
      }),
    }).then(() => history.push("/dashboard/posts"));
  };

  const changeActive = () => {
    if (active === "aktiv") {
      setActive("inaktiv");
    } else {
      setActive("aktiv");
    }
  };

  return (
    <div className="dashboard">
      <form
        method="post"
        enctype="multipart/form-data"
        encType="multipart/form-data"
        action="javascript:void(0);"
      >
        <div className="dashboard__wrapper">
          <div className="dashboard__topline">
            <div className="dashboard__toplineHeadline">Beitrag bearbeiten</div>
          </div>
          <div className="dashboard__inputs">
            <div className="dashboard__inputsInputLeft">
              <input
                className="dashboard__inputsInputOneLiner"
                placeholder="Titel"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <input
                className="dashboard__inputsInputOneLiner"
                placeholder="Untertitel"
                type="text"
                value={subtitle}
                onChange={(e) => setSubtitle(e.target.value)}
              />
              <input
                className="dashboard__inputsInputOneLiner"
                placeholder="Text zum Link"
                type="text"
                value={sourceText}
                onChange={(e) => setSourceText(e.target.value)}
              />
              <input
                className="dashboard__inputsInputOneLiner"
                placeholder="URL der Quelle"
                type="text"
                value={sourceURL}
                onChange={(e) => setSourceURL(e.target.value)}
              />
              <input
                className="dashboard__inputsInputOneLiner"
                placeholder="Text wo die Quelle herstammt"
                type="text"
                value={sourceDescription}
                onChange={(e) => setSourceDesription(e.target.value)}
              />
              <input
                className="dashboard__inputsInputOneLiner"
                placeholder="Bubble Slogan"
                type="text"
                value={bubbleSlogan}
                onChange={(e) => setBubbleSlogan(e.target.value)}
              />
              <select
                value={category}
                className="dashboard__inputsInputOneLiner"
                placeholder="Titel"
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="Wirtschaft">Wirtschaft</option>
                <option value="Gesundheit">Gesundheit</option>
                <option value="Familie">Familie</option>
                <option value="Finanzen">Finanzen</option>
                <option value="Wissenschaft">Wissenschaft</option>
                <option value="Politik">Politik</option>
              </select>
            </div>
            <div className="dashboard__inputsInputRight">
              <TextareaAutosize
                placeholder="Beitragstext"
                minRows={8}
                style={{
                  width: "30vw",
                  border: "none",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingLeft: 10,
                  fontSize: 20,
                  fontFamily: "Roboto",
                  borderRadius: 0,
                  backgroundColor: "rgb(241, 241, 241)",
                }}
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
              <div className="dashboard__inputsInputImg">
                {imageURL === "" ? (
                  <div></div>
                ) : (
                  <img
                    className="dashboard__inputsInputImgImg"
                    src={imageURL}
                  />
                )}
              </div>
              <div className="dashboard__inputsInputImg">
                <div>Dateiupload max 10MB</div>
              </div>
              <input
                accept=".jfif, .pjpeg, .pjp, .jpeg, .jpg, .png"
                type="file"
                id="imgInp"
                name="files[]"
                className="dashboard__inputsInputOneLiner"
                onChange={(e) => setImageUploads(e.target.value)}
                multiple
              />
              <div className="dashboard__inputsInputImg">
                {active === "aktiv" ? (
                  <div
                    className="dashboard__inputsInputCheckbox hoverable"
                    onClick={() => changeActive()}
                  >
                    <CheckIconFilled /> Beitrag aktiv
                  </div>
                ) : (
                  <div
                    className="dashboard__inputsInputCheckbox hoverable"
                    onClick={() => changeActive()}
                  >
                    <CheckIconNotFilled /> Beitrag inaktiv
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="dashboard__inputsSubmit">
            <input
              type="submit"
              name="submit"
              id="submitButton123"
              onClick={(e) => handleUpload(e)}
              title="Einloggen"
              className="dashboard__inputsSubmitButton"
              value="Änderungen speichern"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditPost;

const CheckIconNotFilled = styled(RiCheckboxCircleLine)`
  font-size: 35px;
  width: auto;
  justify-content: center;
  padding-right: 18px;
  padding-top: 3px;
`;

const CheckIconFilled = styled(RiCheckboxCircleFill)`
  font-size: 35px;
  width: auto;
  justify-content: center;
  padding-right: 18px;
  padding-top: 3px;
`;
