export const transformUnixDateToNormalDate = (date, format) => {
  var ReadableDate = new Date(date * 1000);

  // 01.01.21 13:00
  if (format === "dd.mm.yy hh:mm") {
    let res =
      ReadableDate.getDate() +
      "." +
      (ReadableDate.getMonth() + 1) +
      "." +
      ReadableDate.getFullYear() +
      " " +
      ReadableDate.getHours() +
      ":" +
      ReadableDate.getMinutes();
    return res;
  }

  // 01.Jan 13:00
  if (format === "dd.MM hh:mm") {
  }

  // 01.01.21
  if (format === "dd.mm.yy") {
    let res =
      ReadableDate.getDate() +
      "." +
      (ReadableDate.getMonth() + 1) +
      "." +
      ReadableDate.getFullYear();
    return res;
  }

  // 01.Jan
  if (format === "dd.MM") {
  }

  var today = new Date();
  const actualMonth = today.getMonth() + 1;
  const actualYear = today.getFullYear();
  const actualDay = today.getDate();
  const actualHours = today.getHours();
  const actualMinutes = today.getMinutes();
  const actualSeconds = today.getSeconds();

  return (
    new Date(
      actualYear,
      actualMonth - 1,
      actualDay,
      actualHours,
      actualMinutes,
      actualSeconds
    ).getTime() / 1000
  );
};
