import React, { useEffect, useState } from "react";
import HomeThemeCard from "./HomeThemeCard";

function HomeTheme({ collection, cards }) {
  const [posts, setPosts] = useState([]);
  const [response, setResponse] = useState("");

  useEffect(() => {
    if (collection) {
      setPosts([]);
      // fetch posts
      JSON.parse(collection.posts).map(async (post) => {
        const res = await fetch(
          "https://www.menschmeierapi.exconsilio.com/publicPages/posts.php?id=" +
            post.id,
          {
            headers: {
              "Content-type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
            method: "GET",
            // body: JSON.stringify({
            //  companyname: "dancemonkey"
            // })
          }
        );
        const resp = await res.text().then((value) => {
          setPosts((oldArray) => [...oldArray, JSON.parse(value)[0]]);
        });
      });
    }
  }, [collection]);

  if (collection.status === "inaktiv") {
    return <div></div>;
  }
  return (
    <div>
      <div className="home__themeWrapper">
        <div className="home__themeWrapperHeadline">{collection.title}</div>
        <div className="home__themeWrapperCards">
          {posts?.map((post) => {
            return <HomeThemeCard key={"card" + post.id} prop={post} />;
          })}
        </div>
      </div>
    </div>
  );
}

export default HomeTheme;
