import React, { useEffect, useState } from "react";
import "../styles/Dashboard.css";
import { FiEdit } from "react-icons/fi";
import styled from "styled-components/macro";
import { Link, useHistory } from "react-router-dom";
import TextareaAutosize from "react-textarea-autosize";
import { transformUnixDateToNormalDate } from "../globalFunctions/transformUnixDateToNormalDate";
import { getTodayAsUnixDate } from "../globalFunctions/getTodayAsUnixDate";
import { Redirect } from "react-router";
import { RiCheckboxCircleLine, RiCheckboxCircleFill } from "react-icons/ri";

function CreatePost({ store, setStore, userData }) {
  const [title, setTitle] = useState("");
  const [subtitle, setSubtitle] = useState("");
  const [text, setText] = useState("");
  const [sourceURL, setSourceURL] = useState("");
  const [sourceText, setSourceText] = useState("");
  const [sourceDescription, setSourceDesription] = useState("");
  const [bubbleSlogan, setBubbleSlogan] = useState("");
  const [category, setCategory] = useState("");
  const [active, setActive] = useState("aktiv");

  const [imageUploads, setImageUploads] = useState("");

  const url = "https://www.menschmeierapi.exconsilio.com/images/process.php";
  const form = document.querySelector("form");
  let submitButton = document.getElementById("submitButton123");

  useEffect(() => {
    submitButton = document.getElementById("submitButton123");
    if (imageUploads === "") {
      if (submitButton !== null) {
        submitButton.disabled = true;
      }
    } else {
      if (submitButton !== null) {
        submitButton.disabled = false;
      }
    }
  }, [title, imageUploads]);

  const handleUpload = async (e) => {
    e.preventDefault();

    if (submitButton !== null) {
      submitButton.disabled = true;
    }
    const files = document.querySelector("[type=file]").files;
    const formData = new FormData();

    for (let i = 0; i < files.length; i++) {
      let file = files[i];

      formData.append("files[]", file);
    }

    const res = await fetch(url, {
      headers: {
        Authorization: `Bearer ${store?.JWT}`,
      },
      method: "POST",
      body: formData,
    });

    await res.text().then((value) => uploadToDatabase(value));
  };

  let history = useHistory();

  const uploadToDatabase = async (imgurl) => {
    await fetch("https://www.menschmeierapi.exconsilio.com/posts/", {
      method: "PUT",
      headers: {
        Authorization: `Bearer ${store?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        title: title,
        subtitle: subtitle,
        text: text,
        sourceURL: sourceURL,
        sourceText: sourceText,
        sourceDescription: sourceDescription,
        imgURL: imgurl,
        createdAt: getTodayAsUnixDate(),
        creatorID: userData.id ? userData.id : "0",
        bubbleSlogan: bubbleSlogan,
        category: category,
        status: active,
      }),
    }).then(() => history.push("/dashboard/posts"));
  };

  const changeActive = () => {
    if (active === "aktiv") {
      setActive("inaktiv");
    } else {
      setActive("aktiv");
    }
  };

  /* let file; // bildupload

  const isEmpty = (obj) => {
    if (obj === undefined || obj === null || Object.keys(obj).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  // upload POST into posts
  const handlePost = () => {
    //
  };


  let file;
  const handleImageInput = (e) => {
    file = document.getElementById("imgInp").files[0];
    if (file) {
      setImageUploads(file);
    }
  };

  const form = document.getElementById("form");

  form?.addEventListener("submit", async (e) => {
    e.preventDefault();

    file = document.getElementById("imgInp").files[0];
    console.log(file);
    const formData = new FormData();
    formData.append("menschmeierUpload", imageUploads);
    console.log(formData);

    await fetch("https://www.menschmeierapi.exconsilio.com/images/", {
      // Your POST endpoint
      method: "POST",
      headers: {
        // Content-Type may need to be completely **omitted**
        // or you may need something
        Authorization: `Bearer ${store?.JWT}`,
        "Content-Type":
          "multipart; boundary=----WebKitFormBoundary1YComLy6H3XQhEWc",
      },
      body: file, // This is your file object
    })
      .then(
        (response) => console.log(response) // if the response is a JSON object
      )
      .then(
        (success) => console.log(success) // Handle the success response object
      )
      .catch(
        (error) => console.log(error) // Handle the error response object
      );
  });

  const handleFileUpload = async () => {
    const formData = new FormData();
    //  formData.append("menschmeierUpload", file);
    await fetch("https://www.menschmeierapi.exconsilio.com/images/", {
      // Your POST endpoint
      method: "POST",
      headers: {
        // Content-Type may need to be completely **omitted**
        // or you may need something
        Authorization: `Bearer ${store?.JWT}`,
        "Content-Type":
          "multipart/form-data; boundary=----WebKitFormBoundary1YComLy6H3XQhEWc",
      },
      body: formData, // This is your file object
    })
      .then(
        (response) => console.log(response) // if the response is a JSON object
      )
      .then(
        (success) => console.log(success) // Handle the success response object
      )
      .catch(
        (error) => console.log(error) // Handle the error response object
      );
  };
*/

  return (
    <div className="dashboard">
      <form
        method="post"
        enctype="multipart/form-data"
        encType="multipart/form-data"
        action="javascript:void(0);"
      >
        <div className="dashboard__wrapper">
          <div className="dashboard__topline">
            <div className="dashboard__toplineHeadline">
              Neuen Beitrag erstellen
            </div>
          </div>
          <div className="dashboard__inputs">
            <div className="dashboard__inputsInputLeft">
              <input
                className="dashboard__inputsInputOneLiner"
                placeholder="Titel"
                type="text"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
              <input
                className="dashboard__inputsInputOneLiner"
                placeholder="Untertitel"
                type="text"
                value={subtitle}
                onChange={(e) => setSubtitle(e.target.value)}
              />
              <input
                className="dashboard__inputsInputOneLiner"
                placeholder="Text zum Link"
                type="text"
                value={sourceText}
                onChange={(e) => setSourceText(e.target.value)}
              />
              <input
                className="dashboard__inputsInputOneLiner"
                placeholder="URL der Quelle"
                type="text"
                value={sourceURL}
                onChange={(e) => setSourceURL(e.target.value)}
              />
              <input
                className="dashboard__inputsInputOneLiner"
                placeholder="Text wo die Quelle herstammt"
                type="text"
                value={sourceDescription}
                onChange={(e) => setSourceDesription(e.target.value)}
              />
              <input
                className="dashboard__inputsInputOneLiner"
                placeholder="Bubble Slogan"
                type="text"
                value={bubbleSlogan}
                onChange={(e) => setBubbleSlogan(e.target.value)}
              />
              <select
                value={category}
                className="dashboard__inputsInputOneLiner"
                placeholder="Titel"
                onChange={(e) => setCategory(e.target.value)}
              >
                <option value="Wirtschaft">Wirtschaft</option>
                <option value="Gesundheit">Gesundheit</option>
                <option value="Familie">Familie</option>
                <option value="Finanzen">Finanzen</option>
                <option value="Wissenschaft">Wissenschaft</option>
                <option value="Politik">Politik</option>
              </select>
            </div>
            <div className="dashboard__inputsInputRight">
              <TextareaAutosize
                placeholder="Beitragstext"
                minRows={8}
                style={{
                  width: "30vw",
                  border: "none",
                  paddingTop: 5,
                  paddingBottom: 5,
                  paddingLeft: 10,
                  fontSize: 20,
                  fontFamily: "Roboto",
                  borderRadius: 0,
                  backgroundColor: "rgb(241, 241, 241)",
                }}
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
              <div className="dashboard__inputsInputImg">
                <div>Dateiupload max 10MB</div>
              </div>
              <input
                accept=".jfif, .pjpeg, .pjp, .jpeg, .jpg, .png"
                type="file"
                id="imgInp"
                name="files[]"
                className="dashboard__inputsInputOneLiner"
                onChange={(e) => setImageUploads(e.target.value)}
                multiple
              />
              <div className="dashboard__inputsInputImg">
                {active === "aktiv" ? (
                  <div
                    className="dashboard__inputsInputCheckbox hoverable"
                    onClick={() => changeActive()}
                  >
                    <CheckIconFilled /> Beitrag aktiv
                  </div>
                ) : (
                  <div
                    className="dashboard__inputsInputCheckbox hoverable"
                    onClick={() => changeActive()}
                  >
                    <CheckIconNotFilled /> Beitrag inaktiv
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="dashboard__inputsSubmit">
            <input
              type="submit"
              name="submit"
              id="submitButton123"
              onClick={(e) => handleUpload(e)}
              title="Einloggen"
              className="dashboard__inputsSubmitButton"
              value="Beitrag erstellen"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default CreatePost;

const EditIcon = styled(FiEdit)`
  font-size: 30px;
  color: #000;
  width: auto;
  justify-content: center;
  padding-right: 18px;
  padding-top: 3px;
`;

const CheckIconNotFilled = styled(RiCheckboxCircleLine)`
  font-size: 35px;
  width: auto;
  justify-content: center;
  padding-right: 18px;
  padding-top: 3px;
`;

const CheckIconFilled = styled(RiCheckboxCircleFill)`
  font-size: 35px;
  width: auto;
  justify-content: center;
  padding-right: 18px;
  padding-top: 3px;
`;
