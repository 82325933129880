import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import HomePost from "./components/HomePost";

function Post() {
  const { id } = useParams();
  const [posts, setPosts] = useState([]);

  useEffect(async () => {
    if (id) {
      setPosts([]);
      // fetch posts
      const res = await fetch(
        "https://www.menschmeierapi.exconsilio.com/publicPages/posts.php?id=" +
          id,
        {
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          },
          method: "GET",
          // body: JSON.stringify({
          //  companyname: "dancemonkey"
          // })
        }
      );
      const resp = await res.text().then((value) => {
        setPosts((oldArray) => [...oldArray, JSON.parse(value)[0]]);
      });
    }
  }, [id]);

  return (
    <div>
      {posts?.map((post) => {
        return (
          <HomePost
            key={post.id}
            id={post.id}
            img={post.imgURL}
            direction="right"
            title={
              <React.Fragment>
                {post.title.split("\n").map((str) => (
                  <p>{str}</p>
                ))}
              </React.Fragment>
            }
            subtitle={
              <React.Fragment>
                {post.subtitle.split("\n").map((str) => (
                  <p>{str}</p>
                ))}
              </React.Fragment>
            }
            text={
              <React.Fragment>
                {post.text.split("\n").map((str) => (
                  <p>{str}</p>
                ))}
              </React.Fragment>
            }
            sourceText={
              <React.Fragment>
                {post.sourceText.split("\n").map((str) => (
                  <p>{str}</p>
                ))}
              </React.Fragment>
            }
            sourceLink={<React.Fragment>{post.sourceLink}</React.Fragment>}
            sourceDescription={
              <React.Fragment>
                {post.sourceDescription.split("\n").map((str) => (
                  <p>{str}</p>
                ))}
              </React.Fragment>
            }
            bubbleSlogan={
              <React.Fragment>
                {post.bubbleSlogan.split("\n").map((str) => (
                  <p>{str}</p>
                ))}
              </React.Fragment>
            }
            status={post.status}
          />
        );
      })}
    </div>
  );
}

export default Post;
