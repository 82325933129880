import React, { useEffect, useState } from "react";
import "../styles/Dashboard.css";
import { FiEdit } from "react-icons/fi";
import styled from "styled-components/macro";
import { Link } from "react-router-dom";
import { transformUnixDateToNormalDate } from "../globalFunctions/transformUnixDateToNormalDate";

function Posts({ store, setStore }) {
  const [posts, setPosts] = useState([]);
  const [response, setResponse] = useState("");

  const isEmpty = (obj) => {
    if (obj === undefined || obj === null || Object.keys(obj).length === 0) {
      return true;
    } else {
      return false;
    }
  };

  // get all posts
  useEffect(async () => {
    // fetch posts
    const res = await fetch(
      "https://www.menschmeierapi.exconsilio.com/posts/",
      {
        headers: {
          Authorization: `Bearer ${store?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "GET",
        // body: JSON.stringify({
        //  companyname: "dancemonkey"
        // })
      }
    );
    const resp = await res.text().then((value) => {
      setResponse(value);
    });
  }, [store]);

  useEffect(() => {
    if (response !== "") {
      setPosts(reverseArray(JSON.parse(response)));
    }
  }, [response]);

  const reverseArray = (array) => {
    let helpArray = [];
    for (let i = array.length - 1; i >= 0; i--) {
      helpArray.push(array[i]);
    }
    return helpArray;
  };

  return (
    <div className="dashboard">
      <div className="dashboard__wrapper">
        <div className="dashboard__topline">
          <div className="dashboard__toplineHeadline">Beiträge</div>
          <Link to="/post/create">
            <div className="dashboard__toplineCTA">
              + Neuen Beitrag erstellen
            </div>
          </Link>
        </div>
        <div className="dashboard__board">
          <div className="dashboard__boardRow Top">
            <div className="dashboard__boardRowLeft">
              <div className="dashboard__boardRowID">ID</div>
              <div className="dashboard__boardRowTitle">Titel</div>
            </div>
            <div className="dashboard__boardRowRight">
              <div className="dashboard__boardRowDate">Erstellt am</div>
              <div className="dashboard__boardRowStatus">Status</div>
              <div className="dashboard__boardRowCTA">Bearbeiten</div>
            </div>
          </div>
          {posts.map((post) => {
            let routeTopost = "/post/" + post.id;
            let routeToEditPost = "/dashboard/edit/post/" + post.id;

            return (
              <div className="dashboard__boardRow" key={post.id}>
                <div className="dashboard__boardRowLeft">
                  <div className="dashboard__boardRowID">{post.id}</div>
                  <Link to={routeTopost}>
                    <div className="dashboard__boardRowTitle hoverable">
                      {post.title}
                    </div>
                  </Link>
                </div>
                <div className="dashboard__boardRowRight">
                  <div className="dashboard__boardRowDate">
                    {transformUnixDateToNormalDate(post.createdAt, "dd.mm.yy")}
                  </div>
                  <div className="dashboard__boardRowStatus">{post.status}</div>
                  <Link to={routeToEditPost}>
                    <div className="dashboard__boardRowCTA">
                      <EditIcon />
                    </div>
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Posts;

const EditIcon = styled(FiEdit)`
  font-size: 30px;
  color: #000;
  width: auto;
  justify-content: center;
  padding-right: 18px;
  padding-top: 3px;
`;
