import React from "react";
import "./styles/Login.css";

function Login({ store, setStore }) {
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");

  const signIn = async () => {
    let helpobject = {};

    const setJWT = (data) => {
      helpobject = data;
      console.log(helpobject);
    };

    const storeData = (value) => {
      try {
        localStorage.setItem("@jwtmenschmeier", JSON.stringify(value));
        console.log(value);
      } catch (e) {
        // saving error
      }
    };

    const isEmpty = (obj) => {
      if (obj === undefined || obj === null || Object.keys(obj).length === 0) {
        return true;
      } else {
        return false;
      }
    };

    if (!isEmpty(store)) {
      console.log("redirect!");
    }

    const res = await fetch(
      "https://www.menschmeierapi.exconsilio.com/authenticate",
      {
        method: "POST",
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        body: JSON.stringify({
          username: email,
          password: password,
        }),
      }
    );

    if (res.status >= 0 && res.status <= 299) {
      const jwt = await res.text();
      if (jwt.length > 50) {
        setJWT(jwt);
        storeData(helpobject);
        console.log("localstorage erfolgreich");
        setStore(setStore({ JWT: helpobject }));
        console.log("StoreData ist Da. Redirect!");
      } else {
        alert("Login fehlgeschlagen");
      }
    } else {
      // Handle errors
      console.log(res.status, res.statusText);
    }
  };

  return (
    <div className="login">
      <form action="javascript:void(0);">
        <div className="login__wrapper">
          <div className="login__title">LOGIN</div>
          <div className="login__inputs">
            <div className="login__inputsEmail">
              <input
                className="login__inputsEmailInput"
                placeholder="Email"
                type="text"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="login__inputsPassword">
              <input
                className="login__inputsPasswordInput"
                placeholder="Passwort"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onSubmit={signIn}
              />
            </div>
          </div>
          <div className="login__inputsSubmit">
            <input
              type="submit"
              title="Einloggen"
              className="login__inputsSubmitButton"
              onClick={signIn}
              value="EINLOGGEN"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default Login;
