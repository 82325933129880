import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components/macro";
import { FiEdit } from "react-icons/fi";
import { transformUnixDateToNormalDate } from "../globalFunctions/transformUnixDateToNormalDate";
import "../styles/Dashboard.css";

function Collection({ store, title, id, posts, createdAt, status }) {
  const [collectionPosts, setCollectionPosts] = useState([]);
  const [response, setResponse] = useState("");

  useEffect(() => {
    if (posts) {
      setCollectionPosts([]);
      // fetch posts
      JSON.parse(posts).map(async (post) => {
        const res = await fetch(
          "https://www.menschmeierapi.exconsilio.com/publicPages/?id=" +
            post.id,
          {
            headers: {
              Authorization: `Bearer ${store?.JWT}`,
              "Content-type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
            method: "GET",
            // body: JSON.stringify({
            //  companyname: "dancemonkey"
            // })
          }
        );
        const resp = await res.text().then((value) => {
          setCollectionPosts((oldArray) => [...oldArray, JSON.parse(value)[0]]);
        });
      });
    }
  }, [posts]);

  let routeToCollection = "/dashboard/edit/collection/" + id;

  return (
    <div>
      <div className="dashboard__boardRow" key={id}>
        <div className="dashboard__boardRowLeft">
          <div className="dashboard__boardRowID">{id}</div>
          <div className="dashboard__boardRowTitle">{title}</div>
        </div>
        <div className="dashboard__boardRowRight">
          <div className="dashboard__boardRowDate">
            {transformUnixDateToNormalDate(createdAt, "dd.mm.yy")}
          </div>
          <div className="dashboard__boardRowStatus">{status}</div>
          <Link to={routeToCollection}>
            <div className="dashboard__boardRowCTA">
              <EditIcon />
            </div>
          </Link>
        </div>
      </div>
      <div className="dashboard__collection">
        {collectionPosts.map((post) => (
          <div>{post.title}</div>
        ))}
      </div>
    </div>
  );
}

export default Collection;

const EditIcon = styled(FiEdit)`
  font-size: 30px;
  color: #000;
  width: auto;
  justify-content: center;
  padding-right: 18px;
  padding-top: 3px;
`;
