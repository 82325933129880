import React, { useEffect, useState } from "react";
import "../styles/Dashboard.css";
import { Link } from "react-router-dom";
import { transformUnixDateToNormalDate } from "../globalFunctions/transformUnixDateToNormalDate";
import { isEmpty } from "../globalFunctions/isEmpty";
import { getPost } from "../globalFunctions/getPost";
import Collection from "../components/Collection";

function Collections({ store, setStore }) {
  const [collections, setCollections] = useState([]);
  const [response, setResponse] = useState("");

  // get all posts
  useEffect(async () => {
    // fetch posts
    const res = await fetch(
      "https://www.menschmeierapi.exconsilio.com/collections/",
      {
        headers: {
          Authorization: `Bearer ${store?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "GET",
        // body: JSON.stringify({
        //  companyname: "dancemonkey"
        // })
      }
    );
    const resp = await res.text().then((value) => {
      setResponse(value);
    });
  }, [store]);

  useEffect(() => {
    if (response !== "") {
      setCollections(reverseArray(JSON.parse(response)));
    }
  }, [response]);

  const reverseArray = (array) => {
    let helpArray = [];
    for (let i = array.length - 1; i >= 0; i--) {
      helpArray.push(array[i]);
    }
    return helpArray;
  };

  useEffect(() => {
    if (!isEmpty(collections)) {
      // fetch posts
      JSON.parse(collections[0].posts).map(async (post) => {
        const res = await fetch(
          "https://www.menschmeierapi.exconsilio.com/publicPages/?id=" +
            post.id,
          {
            headers: {
              Authorization: `Bearer ${store?.JWT}`,
              "Content-type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
            method: "GET",
            // body: JSON.stringify({
            //  companyname: "dancemonkey"
            // })
          }
        );
      });
    }
  }, [collections]);

  return (
    <div className="dashboard">
      <div className="dashboard__wrapper">
        <div className="dashboard__topline">
          <div className="dashboard__toplineHeadline">Kollektionen</div>
          <Link to="/collection/create">
            <div className="dashboard__toplineCTA">
              + Neue Kollektion erstellen
            </div>
          </Link>
        </div>
        <div className="dashboard__board">
          <div className="dashboard__boardRow Top">
            <div className="dashboard__boardRowLeft">
              <div className="dashboard__boardRowID">ID</div>
              <div className="dashboard__boardRowTitle">Titel</div>
            </div>
            <div className="dashboard__boardRowRight">
              <div className="dashboard__boardRowDate">Erstellt am</div>
              <div className="dashboard__boardRowStatus">Status</div>
              <div className="dashboard__boardRowCTA">Bearbeiten</div>
            </div>
          </div>
          {collections.map((post) => {
            return (
              <Collection
                store={store}
                key={post.id}
                id={post.id}
                title={post.title}
                createdAt={post.createdAt}
                status={post.status}
                posts={post.posts}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Collections;
