import React, { useEffect, useState } from "react";
import reitzle from "./images/wolfgang-reitzle.png";
import tilschweiger from "./images/tilschweiger.png";
import HomePost from "./components/HomePost";
import HomeTheme from "./components/HomeTheme";
import { isEmpty } from "./globalFunctions/isEmpty";

function Home({
  store,
  handleClickUmfrageButton,
  umfrage,
  ergebnisJa,
  ergebnisNein,
}) {
  const [response, setResponse] = useState("");
  const [publicPages, setpublicPages] = useState();
  const [loading, setLoading] = useState(true);
  const [posts, setPosts] = useState([]);
  const [collection, setCollection] = useState([]);

  useEffect(async () => {
    const res = await fetch(
      "https://www.menschmeierapi.exconsilio.com/publicPages",
      {
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "GET",
        // body: JSON.stringify({
        //  companyname: "dancemonkey"
        // })
      }
    );
    const resp = await res.text().then((value) => setResponse(value));
  }, []);

  useEffect(() => {
    if (response !== "") {
      setpublicPages(JSON.parse(response));
    }
  }, [response]);

  useEffect(() => {
    if (publicPages) {
      setPosts([]);
      // fetch posts
      JSON.parse(publicPages[0].posts).map(async (post) => {
        const res = await fetch(
          "https://www.menschmeierapi.exconsilio.com/publicPages/posts.php?id=" +
            post.id,
          {
            headers: {
              "Content-type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
            method: "GET",
            // body: JSON.stringify({
            //  companyname: "dancemonkey"
            // })
          }
        );
        const resp = await res.text().then((value) => {
          setPosts((oldArray) => [...oldArray, JSON.parse(value)[0]]);
        });
      });
    }
  }, [publicPages]);

  useEffect(() => {
    if (publicPages) {
      setCollection([]);
      // fetch posts
      JSON.parse(publicPages[0].collection).map(async (post) => {
        const res = await fetch(
          "https://www.menschmeierapi.exconsilio.com/publicPages/collections.php?id=" +
            post.id,
          {
            headers: {
              "Content-type":
                "application/x-www-form-urlencoded; charset=UTF-8",
            },
            method: "GET",
            // body: JSON.stringify({
            //  companyname: "dancemonkey"
            // })
          }
        );
        const resp = await res.text().then((value) => {
          setCollection((oldArray) => [...oldArray, JSON.parse(value)[0]]);
        });
      });
    }
  }, [publicPages]);

  return (
    <div className="home">
      <div className="home__parallaxText">
        <div className="home_parallaxTextFlex">MENSCH MEIER!</div>
      </div>
      <div className="home__overlayTop">
        {/* Div ist nur Platzhalter für Parallax Hintergrund */}
        <div className="home__overlayTopPurpleShadow"></div>
        <div className="home__overlayTopCrowd"></div>
      </div>
      <div className="home__staticCcontent">
        <div className="home__staticContentFirst">
          <div className="home__staticContentFirstBox">
            <div className="home__staticContentFirstLeft">
              WER
              <br />
              WIR
              <br />
              SIND
            </div>
            <div className="home__staticContentFirstRight">
              <div className="home__staticContentFirstRightLeft">
                <div className="home__staticContentFirstRightLeftTop">
                  <span style={{ color: "white" }}>„MENSCH MEIER“</span> ist
                  <br />
                  die interessanteste
                  <br />
                  Instanz für Freiheit
                  <br />
                  und Realismus.
                </div>
                <div className="home__staticContentFirstRightLeftMiddle">
                  Wir sind keine Partei und gehören keiner bestehenden
                  politischen Bewegung an.
                </div>
                <div className="home__staticContentFirstRightLeftBottom">
                  Wir folgen keiner Sau, die durchs Dorf getrieben wird, so
                  populär sie auch gerade sein mag. Für uns zählen kritische
                  Distanz und die Freiheit unserer Gedanken und Rede.
                </div>
                <div className="home__staticContentFirstRightLeftButton btn-medium">
                  MEHR
                </div>
              </div>
              <div className="home__staticContentFirstRightRight">
                <div className="home__staticContentFirstRightRightIcon">
                  <div className="home__staticContentFirstRightRightIconText">
                    Unsere Mitdenker
                    <br />
                    stehen für
                    <br />
                    <span className="home__staticContentFirstRightRightIconTextHighlight">
                      Intelligenz,
                      <br />
                      Humor und
                      <br />
                      Tatkraft.
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="home__staticContentSecond">
          <div className="home__staticContentSecondLeft">
            <div className="home__staticContentSecondPositionFixed"></div>
          </div>
          <div className="home__staticContentSecondRight">
            <div className="home__staticContentSecondRightContent">
              <div className="home__staticContentSecondRightContentLeft">
                <div className="home__staticContentSecondRightContentLeftImgQuotes"></div>
              </div>
              <div className="home__staticContentSecondRightContentRight">
                <div className="home__staticContentSecondRightContentRightTop">
                  Wenn alle einer Meinung sind … pack sofort Deine Koffer und
                  verschwinde, sagte mein Vater.
                </div>
                <div className="home__staticContentSecondRightContentRightBottom">
                  (Erika Freeman floh mit 12 Jahren in die USA)
                </div>
              </div>
              <div className="home__staticContentSecondRightContentRightRight">
                <div className="home__staticContentSecondRightContentRightRightImgQuotes"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="home__dynamicContent">
          {posts?.map((post) => {
            for (let [key, value] of Object.entries(post)) {
              if (
                key === "id" &&
                value === JSON.parse(publicPages[0].posts)[0].id
              ) {
                return (
                  <HomePost
                    key={post.id}
                    id={post.id}
                    img={post.imgURL}
                    direction="left"
                    title={
                      <React.Fragment>
                        {post.title.split("\n").map((str) => (
                          <p>{str}</p>
                        ))}
                      </React.Fragment>
                    }
                    subtitle={
                      <React.Fragment>
                        {post.subtitle.split("\n").map((str) => (
                          <p>{str}</p>
                        ))}
                      </React.Fragment>
                    }
                    text={
                      <React.Fragment>
                        {post.text.split("\n").map((str) => (
                          <p>{str}</p>
                        ))}
                      </React.Fragment>
                    }
                    sourceText={
                      <React.Fragment>
                        {post.sourceText.split("\n").map((str) => (
                          <p>{str}</p>
                        ))}
                      </React.Fragment>
                    }
                    sourceLink={
                      <React.Fragment>{post.sourceLink}</React.Fragment>
                    }
                    sourceDescription={
                      <React.Fragment>
                        {post.sourceDescription.split("\n").map((str) => (
                          <p>{str}</p>
                        ))}
                      </React.Fragment>
                    }
                    bubbleSlogan={
                      <React.Fragment>
                        {post.bubbleSlogan.split("\n").map((str) => (
                          <p>{str}</p>
                        ))}
                      </React.Fragment>
                    }
                    status={post.status}
                  />
                );
              }
            }
          })}
          {posts?.map((post) => {
            for (let [key, value] of Object.entries(post)) {
              if (
                key === "id" &&
                value === JSON.parse(publicPages[0].posts)[1].id
              ) {
                return (
                  <HomePost
                    key={post.id}
                    id={post.id}
                    img={post.imgURL}
                    direction="right"
                    title={
                      <React.Fragment>
                        {post.title.split("\n").map((str) => (
                          <p>{str}</p>
                        ))}
                      </React.Fragment>
                    }
                    subtitle={
                      <React.Fragment>
                        {post.subtitle.split("\n").map((str) => (
                          <p>{str}</p>
                        ))}
                      </React.Fragment>
                    }
                    text={
                      <React.Fragment>
                        {post.text.split("\n").map((str) => (
                          <p>{str}</p>
                        ))}
                      </React.Fragment>
                    }
                    sourceText={
                      <React.Fragment>
                        {post.sourceText.split("\n").map((str) => (
                          <p>{str}</p>
                        ))}
                      </React.Fragment>
                    }
                    sourceLink={
                      <React.Fragment>{post.sourceLink}</React.Fragment>
                    }
                    sourceDescription={
                      <React.Fragment>
                        {post.sourceDescription.split("\n").map((str) => (
                          <p>{str}</p>
                        ))}
                      </React.Fragment>
                    }
                    bubbleSlogan={
                      <React.Fragment>
                        {post.bubbleSlogan.split("\n").map((str) => (
                          <p>{str}</p>
                        ))}
                      </React.Fragment>
                    }
                    status={post.status}
                  />
                );
              }
            }
          })}
        </div>
        <div className="home__umfrage">
          <div className="home__umfrageKlammer">
            <div className="home__umfrageKlammerIcon"></div>
          </div>
          <div className="home__umfrageText">
            <div className="home__umfrageTextText">
              Wollen wir uns Framing und Nudging durch Politik und Medien mit
              Charme und Intelligenz aber auch stringenter Offenheit
              widersetzen?
            </div>
            <div className="home__umfrageTextButtons">
              {umfrage === "" ? (
                <div
                  className="home__umfrageTextButtons btn-large"
                  id="yes"
                  onClick={(e) => handleClickUmfrageButton(e)}
                >
                  JA
                </div>
              ) : (
                <div className="home__umfrageTextErgebnis">
                  {ergebnisJa}x Ja
                </div>
              )}

              {umfrage === "" ? (
                <div
                  className="home__umfrageTextButtons btn-large"
                  id="no"
                  onClick={(e) => handleClickUmfrageButton(e)}
                >
                  Nein
                </div>
              ) : (
                <div className="home__umfrageTextErgebnis">
                  {" "}
                  {ergebnisNein}x Nein
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="home__theme">
          {collection.map((col) => {
            return <HomeTheme key={col.id} collection={col} />;
          })}
        </div>
        <div className="home__overlayBottom">
          {/* Div ist nur Platzhalter für Parallax Hintergrund */}
          <div className="home__overlayBottomFooter">
            <div className="home__overlayBottomFooterWrapper">
              <div className="home__overlayBottomFooterLinks">
                <div className="home__overlayBottomFooterLinksLink">JOB</div>
                <div className="home__overlayBottomFooterLinksLink">
                  IMPRESSUM
                </div>
                <div className="home__overlayBottomFooterLinksLink">
                  DATENSCHUTZ
                </div>
              </div>
              <div className="home__overlayBottomFooterInteresse">
                <div className="home__overlayBottomFooterInteresseLeft">
                  DAS INTERESSIERT MICH
                </div>
                <div className="home__overlayBottomFooterInteresseRight">
                  <div className="home__overlayBottomFooterInteresseRightIcon"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="home__overlayBottomPurpleShadow">
            <div className="home__overlayBottomPurpleShadowWrapper">
              <div className="home__overlayBottomPurpleShadowIconBox">
                <div className="home__overlayBottomPurpleShadowIcon">
                  <div className="home__overlayBottomPurpleShadowIconText">
                    Haltet mich
                    <br />
                    auf dem
                    <br />
                    Laufenden.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
