import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import "../styles/Dashboard.css";
import { FiEdit } from "react-icons/fi";
import styled from "styled-components/macro";
import TextareaAutosize from "react-textarea-autosize";
import { transformUnixDateToNormalDate } from "../globalFunctions/transformUnixDateToNormalDate";
import { getTodayAsUnixDate } from "../globalFunctions/getTodayAsUnixDate";
import { Redirect } from "react-router";
import { isEmpty } from "../globalFunctions/isEmpty";

function EditHome({ store, setStore }) {
  const [publicPages, setpublicPages] = useState();

  const [beitrag1, setBeitrag1] = useState([]);
  const [beitrag2, setBeitrag2] = useState([]);
  const [collections, setCollections] = useState([]);

  const [posts, setPosts] = useState([]);

  const [beitragCollection, setBeitragCollection] = useState([]);
  const [response, setResponse] = useState("");
  const [responsePosts, setResponsePosts] = useState("");
  const [responseCollections, setResponseCollections] = useState("");

  // get homepage Details
  useEffect(async () => {
    const res = await fetch(
      "https://www.menschmeierapi.exconsilio.com/publicPages",
      {
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "GET",
        // body: JSON.stringify({
        //  companyname: "dancemonkey"
        // })
      }
    );
    const resp = await res.text().then((value) => setResponse(value));
  }, []);

  useEffect(() => {
    if (response !== "") {
      setpublicPages(JSON.parse(response));
    }
  }, [response]);

  // get all posts
  useEffect(async () => {
    // fetch posts
    const res = await fetch(
      "https://www.menschmeierapi.exconsilio.com/posts/",
      {
        headers: {
          Authorization: `Bearer ${store?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "GET",
        // body: JSON.stringify({
        //  companyname: "dancemonkey"
        // })
      }
    );
    const resp = await res.text().then((value) => {
      setResponsePosts(value);
    });
  }, [response]);

  // get all collections
  useEffect(async () => {
    // fetch posts
    const res = await fetch(
      "https://www.menschmeierapi.exconsilio.com/collections/",
      {
        headers: {
          Authorization: `Bearer ${store?.JWT}`,
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        },
        method: "GET",
        // body: JSON.stringify({
        //  companyname: "dancemonkey"
        // })
      }
    );
    const resp = await res.text().then((value) => {
      setResponseCollections(value);
    });
  }, [response]);

  //set posts and collection from publicpage to state
  useEffect(() => {
    if (responsePosts !== "") {
      setPosts(JSON.parse(responsePosts));
    }
  }, [responsePosts]);

  //set posts and collection from publicpage to state
  useEffect(() => {
    if (responseCollections !== "") {
      setCollections(JSON.parse(responseCollections));
    }
  }, [responseCollections]);

  useEffect(() => {
    if (!isEmpty(publicPages)) {
      setBeitrag1(JSON.parse(publicPages[0].posts)[0].id);
      setBeitrag2(JSON.parse(publicPages[0].posts)[1].id);
      setBeitragCollection(JSON.parse(publicPages[0].collection)[0].id);
    }
  }, [publicPages]);

  let submitButton = document.getElementById("submitButton123");

  useEffect(() => {
    submitButton = document.getElementById("submitButton123");
    if (isEmpty(beitrag1) && isEmpty(beitrag2) && isEmpty(beitragCollection)) {
      if (submitButton !== null) {
        submitButton.disabled = true;
      }
    } else {
      if (submitButton !== null) {
        submitButton.disabled = false;
      }
    }
  }, [beitrag1, beitrag2, beitragCollection]);

  let history = useHistory();

  const handleUpload = async (imgurl) => {
    let postString = '[{"id":"' + beitrag1 + '"},{"id":"' + beitrag2 + '"}]';

    let collectionString = '[{"id":"' + beitragCollection + '"}]';

    await fetch("https://www.menschmeierapi.exconsilio.com/homepage/?id=1", {
      method: "POST",
      headers: {
        Authorization: `Bearer ${store?.JWT}`,
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: JSON.stringify({
        posts: postString,
        collection: collectionString,
      }),
    }).then(() => history("/"));
  };

  return (
    <div className="dashboard">
      <form action="javascript:void(0);">
        <div className="dashboard__wrapper">
          <div className="dashboard__topline">
            <div className="dashboard__toplineHeadline">
              Startseite bearbeiten
            </div>
          </div>
          <div className="dashboard__collectionInputs">
            <div className="dashboard__collectionInputsBottom">
              <select
                value={beitrag1}
                className="dashboard__inputsInputOneLiner"
                placeholder="Titel"
                onChange={(e) => setBeitrag1(e.target.value)}
              >
                {posts?.map((post) => {
                  return (
                    <option key={post.id} value={post.id}>
                      {post.title}
                    </option>
                  );
                })}
              </select>
              <select
                value={beitrag2}
                className="dashboard__inputsInputOneLiner"
                placeholder="Titel"
                onChange={(e) => setBeitrag2(e.target.value)}
              >
                {posts?.map((post) => {
                  return (
                    <option key={post.id} value={post.id}>
                      {post.title}
                    </option>
                  );
                })}
              </select>
              <select
                value={beitragCollection}
                className="dashboard__inputsInputOneLiner"
                placeholder="Titel"
                onChange={(e) => setBeitragCollection(e.target.value)}
              >
                {collections.map((post) => {
                  return (
                    <option key={post.id} value={post.id}>
                      {post.title}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
          <div className="dashboard__inputsSubmit">
            <input
              type="submit"
              name="submit"
              id="submitButton123"
              onClick={(e) => handleUpload(e)}
              title="Einloggen"
              className="dashboard__inputsSubmitButton"
              value="Änderungen speichern"
            />
          </div>
        </div>
      </form>
    </div>
  );
}

export default EditHome;

const EditIcon = styled(FiEdit)`
  font-size: 30px;
  color: #000;
  width: auto;
  justify-content: center;
  padding-right: 18px;
  padding-top: 3px;
`;
