export const getTodayAsUnixDate = () => {
  var today = new Date();
  const actualMonth = today.getMonth() + 1;
  const actualYear = today.getFullYear();
  const actualDay = today.getDate();
  const actualHours = today.getHours();
  const actualMinutes = today.getMinutes();
  const actualSeconds = today.getSeconds();

  return (
    new Date(
      actualYear,
      actualMonth - 1,
      actualDay,
      actualHours,
      actualMinutes,
      actualSeconds
    ).getTime() / 1000
  );
};
