import React from "react";
import { Link } from "react-router-dom";
import "./styles/NavbarBottom.css";

function NavbarBottom({ store, userData }) {
  const isEmpty = (obj) => {
    if (obj === undefined || obj === null || Object.keys(obj).length === 0) {
      return true;
    } else {
      return false;
    }
  };
  return (
    <div className="navbarbottom">
      {isEmpty(store) ? (
        <div className="navbarbottom__wrapper">
          <div className="navbarbottom__wrapperLeft">
            AUDIATUR ET ALTERA PARS
          </div>
          <div className="navbarbottom__wrapperRight">
            HÖRE AUCH DIE GEGENSEITE
          </div>
        </div>
      ) : (
        <div className="navbarbottom__wrapper">
          <div className="navbarbottom__wrapperLeft">
            {!isEmpty(userData) ? (
              <div>Hallo {userData.firstname}</div>
            ) : (
              <div></div>
            )}
          </div>
          <div className="navbarbottom__wrapperRight">
            <Link to="/dashboard/posts">
              <div>Beiträge</div>
            </Link>
            <Link to="/dashboard/collections">
              <div>Kollektionen</div>
            </Link>
            <Link to="/dashboard/edit/homepage">
              <div>Startseite</div>
            </Link>
          </div>
        </div>
      )}
    </div>
  );
}

export default NavbarBottom;
